import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { floatingData, floatingData2 } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import AboutUsServices from '../../components/aboutus/services'
import './styles.scss'
import Testimonials from '../../components/global/testimonials'

const AboutUs = () => {
    return (
        <Fragment>

            <Introduction
                height={100}
                title1="ABOUT US"
                mainTitle="WHAT MAKES US THE BEST CHOICE?"
                title2="With extensive experience and expertise in recovering lost funds, we have successfully assisted numerous victims in retrieving millions of dollars. Join our satisfied clients who have regained their assets with our assistance."
                backgroundImage={background}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <AboutUsServices />

            <div style={{ marginTop: 32 }}>
                <p className='aboutusServiceTitle'>
                    SERVICES
                </p>
                <FloatingListSection
                    margin='64px 0px'
                    data={floatingData}
                />
            </div>

            <Introduction
                height={50}
                title1="EXPERTS IN CRYPTO FUND RECOVERY"
                mainTitle="Restoring Lost Cryptocurrency"
                title2="Our expertise lies in restoring lost cryptocurrency, ensuring you have the highest chance of recovering your funds."
                backgroundImage='none'
                mobileBackgroundImage='none'
                backgroundColor='black'
                mobileBackgroundColor='black'
            />

            <div style={{ width: '100%', backgroundColor: 'black' }}>
                <FloatingListSection
                    margin='0px'
                    data={floatingData2}
                />
            </div>

            <Testimonials
                title='WE’VE HELPED MANY PEOPLE'
                subTitle='RECOVER THEIR LOST FUNDS'
            />
        </Fragment>
    )
}

export default AboutUs