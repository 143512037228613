import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingListSection from '../../components/global/floatingsection'
import { floatingData } from '../../data'
import RecoveryService from '../../components/homepage/recoveryservice'
import Testimonials from '../../components/global/testimonials'

const HomePage = () => {

    return (
        <Fragment>

            <Introduction
                height={100}
                title1="RECOVER LOST FUNDS WITHOUT UPFRONT PAYMENT."
                mainTitle="REGAIN ACCESS TO LOST MONEY."
                title2="Let us help you recover your lost cryptocurrency without any initial payment required."
                backgroundImage={background}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'

            />

            <FloatingListSection
                margin='64px 0px'
                data={floatingData}
            />

            <RecoveryService />

            <Testimonials
                title='CUSTOMER REVIEWS'
                subTitle='Explore what our valued customers have to say.'
            />

        </Fragment>
    )
}

export default HomePage