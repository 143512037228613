import React from 'react'
import './styles.scss'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FloatingListSection from '../../global/floatingsection'
import { floatingData2 } from '../../../data'

const RecoveryService = () => {
    return (
        <div className='recoveryServiceWrapper'>
            <Container>
                <div className='recoveryServiceTitleOne' data-aos="fade-up" data-aos-duration="500">
                    <p>HIGHLY RELIABLE AND DEPENDABLE</p>
                </div>
                <div className='recoveryServiceTitleTwo' data-aos="fade-up" data-aos-duration="1000">
                    <p>EXPERT CRYPTO ASSET RECOVERY SOLUTIONS</p>
                </div>
                <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                    <p style={{ color: 'white' }}>
                        Harness our expertise in retrieving lost funds, enabling countless victims worldwide to
                        reclaim millions of dollars' worth of stolen and inaccessible cryptocurrencies. Allow us to
                        spare you the ceaseless torment of endless days and sleepless nights, struggling to regain control
                        over your wealth.
                    </p>
                </div>
                <div className='recoverySubTitle' data-aos="fade-up" data-aos-duration="2000">
                    <p>CRAFTING SUCCESS THROUGH OUR VAST EXPERIENCE</p>
                </div>
                <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                    <p style={{ color: 'white' }}>
                        Partnering with esteemed industry leaders, specialists, and cutting-edge
                        blockchain forensic tools, we employ a blend of established blockchain analytics
                        and investigative methodologies to effectively restore your funds.
                    </p>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                    <Link to='/more'>
                        <Button className='recoveryServiceButton'>
                            More
                        </Button>
                    </Link>
                </div>
                <FloatingListSection
                    margin='64px 0px 0px 0px'
                    data={floatingData2}
                />
            </Container>
        </div>
    )
}

export default RecoveryService