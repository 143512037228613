import React from 'react'
import background2 from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import TicketsProgressFields from './ticketprogressfields'
import Introduction2 from '../../components/global/introduction2'

const TicketProgress = () => {
    return (
        <Fragment>
            <Introduction2
                height={100}
                title1="Ticket Progress"
                mainTitle="Track Your Ticket"
                title2="Stay updated on the progress of your ticket and track its status through this page. We understand the importance of timely communication, and our ticket progress page allows you to monitor the steps taken to address your issue or request. Check for updates, view assigned actions, and rest assured that we're actively working towards a resolution."
                backgroundImage={background2}
                mobileBackgroundImage='none'
                backgroundColor='none'
                mobileBackgroundColor='black'
                children={
                    <FloatingContainer
                        children={
                            <TicketsProgressFields />
                        }
                    />
                }
            />
        </Fragment>
    )
}

export default TicketProgress