import React, { Fragment } from 'react'
import './styles.scss'
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

const Introduction2 = ({
    title1,
    mainTitle,
    title2,
    height,
    backgroundImage,
    backgroundColor,
    mobileBackgroundImage,
    mobileBackgroundColor,
    children
}) => {

    const Container = styled.div`
        background-image: url(${backgroundImage});
        background-color: ${backgroundColor};

        @media (max-width: 600px) {
            background-image: url(${mobileBackgroundImage});
            background-color: ${mobileBackgroundColor};
        }
        `;

    return (
        <Fragment>
            <div className='introduction2Wrapper' style={{ height: `${height}vh` }}>
                <Container
                    className='introduction2ImageContainer'
                >
                    <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12}>
                            <div className='introduction2TitlesContainer'>
                                <div className='introduction2FirstTitle' data-aos="fade-up" data-aos-duration="500">
                                    <p>{title1}</p>
                                </div>
                                <div className='introduction2MainTitle' data-aos="fade-up" data-aos-duration="1000">
                                    <p>{mainTitle}</p>
                                </div>
                                <div className='introduction2SecondTitle' data-aos="fade-up" data-aos-duration="1500">
                                    <p>{title2}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12}>
                            <div className='introduction2ChildrenContainer'>
                                {children}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default Introduction2