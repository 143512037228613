import React from 'react'
import './styles.scss'
import { services } from '../../../data'

const ServicesCards = () => {
    return (
        <div className='serviceWrapper'>
            <div className='serviceTitle' data-aos="fade-up" data-aos-duration="500">
                <p>Services</p>
            </div>
            <div className='serviceCardContainer' data-aos="fade-up" data-aos-duration="1000">
                {
                    services.map(service => (
                        <div class="card">
                            <div class="image">
                                <div>{service.icon}</div>
                            </div>
                            <div class="details">
                                <div class="center">
                                    <h1>{service.name}</h1>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ServicesCards