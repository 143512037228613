import React, { Fragment } from 'react'
import background2 from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingContainer from '../../components/global/floatingContainer'
import SignInFields from './signInFields'
import Introduction2 from '../../components/global/introduction2'

const SignIn = () => {
    return (
        <Fragment>
            <Introduction2
                height={100}
                title1="Welcome Back!"
                mainTitle="Sign In Here!"
                title2="Enter your credentials to access your account and enjoy a personalized experience."
                backgroundImage={background2}
                mobileBackgroundImage='none'
                backgroundColor='none'
                mobileBackgroundColor='black'
                children={
                    <FloatingContainer
                        children={
                            <SignInFields />
                        }
                    />
                }
            />
        </Fragment>
    )
}

export default SignIn