import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { floatingData, serviceHighlight } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import ServicesCards from '../../components/services/servicesCard'
import ServiceHighlight from '../../components/services/serviceHighlight'
import StyledAccordion from '../../components/global/accordion'

const Services = () => {
    return (
        <Fragment>

            <Introduction
                height={100}
                title1="WHY CHOOSE US?"
                mainTitle="PROVEN TRACK RECORD OF SUCCESS"
                title2="With a proven track record of successfully recovering funds, we have gained the trust of numerous scam victims worldwide, restoring millions of dollars in stolen funds."
                backgroundImage={background}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <ServicesCards />

            <ServiceHighlight />

            <StyledAccordion
                title='QUICK ANSWERS'
                data={serviceHighlight}
            />
        </Fragment>
    )
}

export default Services