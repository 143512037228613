import React, { Fragment } from 'react'
import background2 from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingContainer from '../../components/global/floatingContainer'
import ProfileFields from './profileFields'
import Introduction2 from '../../components/global/introduction2'

const Profile = () => {
    return (
        <Fragment>
            <Introduction2
                height={100}
                title1="My Profile"
                mainTitle="Personal Information"
                title2="Manage and customize your profile to showcase your unique identity and preferences. Update your personal information, add a profile picture, and share your interests to enhance your online presence within our community."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
                children={
                    <FloatingContainer
                        children={
                            <ProfileFields />
                        }
                    />
                }
            />

        </Fragment>
    )
}

export default Profile