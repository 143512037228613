import React from 'react'
import './styles.scss'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useState } from 'react';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ContactUs, createTicket } from '../../api';
import { useFormik } from 'formik';
import Cookies from 'universal-cookie';
import moment from 'moment/moment';



const MyTicketFields = () => {
    const cookie = new Cookies()
    const [randomNumber, setRandomNumber] = useState("");
    const [phoneValue, setPhoneValue] = useState()
    const [dateLoss, setDateLoss] = useState(new Date());
    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')


    const generateRandomNumber = () => {
        const randomDigits = Math.floor(Math.random() * 99999) + 10000; // Generate a random 5-digit number
        return randomDigits;
    };

    useEffect(() => {
        setRandomNumber(generateRandomNumber());
    }, []);



    const formik = useFormik({
        initialValues: {
            generatedNumber: randomNumber,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            amountLost: '',
            dateLost: dateLoss,
            message: '',
        },
        onSubmit: () => {
            const generatedNumber = randomNumber;
            const firstName = formik.values.firstname;
            const lastName = formik.values.lastname;
            const email = formik.values.email;
            const phoneNumber = phoneValue;
            const amountLost = formik.values.amountLost;
            const dateLost = moment(dateLoss).format('L');
            const message = formik.values.message;

            const data = {
                generatedNumber: randomNumber,
                sender: cookie.get('id')
            }

            const func = ContactUs(
                generatedNumber,
                firstName,
                lastName,
                email,
                phoneNumber,
                amountLost,
                dateLost,
                message
            )
            func.then(async (response) => {
                if (response.success) {
                    setMessage("Ticket Successfully send.")
                    createTicket(data)
                    window.location.reload()
                } else {
                    setErrMessage("Ticket Failure")
                }
            })
        }
    })


    return (
        <div className='myTicketWrapper'>
            <Container>
                {/* <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <h6 className='ticketReceiver mb-3'>To : Support@thecryptoclearance.com</h6>
                </Row> */}
                <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <h4 className='ticketIdTitle mb-3'>Ticket Number : <span># {randomNumber}</span></h4>
                </Row>
                {
                    message &&
                    <h6 style={{ color: 'green', textAlign: 'center', padding: '4px 0px' }}>{message}</h6>
                }
                {
                    errMessage &&
                    <h6 style={{ color: 'red', textAlign: 'center', padding: '4px 0px' }}>{errMessage}</h6>
                }
                <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="formBasicFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter First Name"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstname || ''}
                                        name="firstname"
                                        id="firstname"
                                        className='input'
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="formBasicLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Last Name"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname || ''}
                                        name="lastname"
                                        id="lastname"
                                        className='input'
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Enter email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email || ''}
                                        name="email"
                                        id="email"
                                        className='input'
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="formBasicPhone" >
                                    <Form.Label>Phone Number</Form.Label>
                                    <PhoneInput
                                        required
                                        defaultCountry="US"
                                        className='phoneNumberInput'
                                        placeholder="Enter phone number"
                                        value={phoneValue}
                                        onChange={setPhoneValue}
                                        name="phone"
                                        id="phone"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="amountLost">
                                    <Form.Label>Amount Lost</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Enter amount lost"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.amountLost || ''}
                                        name="amountLost"
                                        id="amountLost"
                                        className='input'
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                                <Form.Group className="mb-3" controlId="formBasicDateLost">
                                    <Form.Label>Date Lost</Form.Label>
                                    <DatePicker
                                        required
                                        className='dateLossInput'
                                        selected={dateLoss}
                                        name='dateLost'
                                        id='dateLost'
                                        onChange={(date) => setDateLoss(date)}
                                        value={dateLoss} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                    className="mb-3"
                                    as="textarea"
                                    rows={3}
                                    placeholder="Message"
                                    name="message"
                                    id="message"
                                    style={{ marginTop: 8 }} />
                            </Col>
                        </Row>
                        <Row style={{ padding: '0px 16px' }}>
                            <Button type="submit" className='sendTicketSubmitButton'>
                                Send Ticket
                            </Button>
                        </Row>

                    </Form>
                </Row>
            </Container>
        </div>
    )
}

export default MyTicketFields