import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingContainer from '../../components/global/floatingContainer'
import SignUpFields from './signUpFields'
import Introduction2 from '../../components/global/introduction2'

const SignUp = () => {
    return (
        <Fragment>
            <Introduction2
                height={100}
                title1="Create Your Account"
                mainTitle="Join Our Community"
                title2="Sign up now to unlock exclusive benefits, connect with like-minded individuals, and embark on an exciting journey in our platform."
                backgroundImage={background2}
                mobileBackgroundImage='none'
                backgroundColor='none'
                mobileBackgroundColor='black'
                children={
                    <FloatingContainer
                        children={
                            <SignUpFields />
                        }
                    />
                }
            />
        </Fragment>
    )
}

export default SignUp