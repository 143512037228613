import {
    FaBitcoin,
    FaIdCard,
    FaUserLock,
    FaMask,
    FaExchangeAlt,
    FaShieldVirus
} from "react-icons/fa";
import { TfiReload } from 'react-icons/tfi'
import { HiMailOpen } from 'react-icons/hi'
import { MdOutlineSyncProblem, MdOutlineVisibilityOff } from "react-icons/md";
import { BsCoin, BsFillClipboardDataFill } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlineCloudDownload } from 'react-icons/ai';
import { FiHardDrive } from 'react-icons/fi';
import { GiBreakingChain } from 'react-icons/gi';

import avatar1 from './assets/images/avatar1.jpg'
import avatar2 from './assets/images/avatar2.jpg'
import avatar3 from './assets/images/avatar3.jpg'
import avatar4 from './assets/images/avatar4.jpg'
import avatar5 from './assets/images/avatar5.jpg'
import avatar6 from './assets/images/avatar6.jpg'
import avatar7 from './assets/images/avatar7.jpg'
import avatar8 from './assets/images/avatar8.jpg'
import avatar9 from './assets/images/avatar9.jpg'
import avatar10 from './assets/images/avatar10.jpg'
import avatar11 from './assets/images/avatar11.jpg'
import avatar12 from './assets/images/avatar12.jpg'
import users from './assets/images/users.png'
import headphones from './assets/images/headphones.png'
import speed from './assets/images/speed.png'
import handshake from './assets/images/handshake.png'
import doctor from './assets/images/doctor.png'


export const links = [
    {
        link: 'Home',
        path: '/',
    },
    {
        link: 'About Us',
        path: '/aboutus',
    },
    {
        link: 'Services',
        path: '/services',
    },
    {
        link: 'more',
        path: '/more',
    },
]

export const loggedInLinks = [
    // {
    //     link: 'Profile',
    //     path: '/profile'
    // },
    {
        link: 'My Ticket',
        path: '/ticket'
    },
    {
        link: 'Ticket Progress',
        path: '/ticket-progress'
    },
]

export const floatingData = [
    {
        id: '1',
        title: 'Trusted Assistance',
        description: 'Our team consists of experienced recovery experts specialized in dealing with online financial and cyber fraud.',
        icon: <img src={users} alt="users" />,
        className: 'DarkTheme',
    },
    {
        id: '2',
        title: 'Reliable Support',
        description: 'We are dedicated to providing you with trustworthy assistance and guiding you throughout the process of reclaiming your lost assets.',
        icon: <img src={headphones} alt="headphones" />,
        className: 'lightTheme'
    },
    {
        id: '3',
        title: 'Efficient Results',
        description: 'Our streamlined process ensures swift and effective outcomes. In most cases, we achieve results within the initial weeks.',
        icon: <img src={speed} alt="speed" />,
        className: 'DarkTheme'
    },
]

export const floatingData2 = [
    {
        id: '1',
        title: 'Seek Assistance',
        description: 'Engage a skilled Recovery Consultant to assess your situation.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme'
    },
    {
        id: '2',
        title: 'Construct Your Case',
        description: 'Gather the essential evidence and devise a winning strategy.',
        icon: <img src={doctor} alt="doctor" />,
        className: 'greenTheme'
    },
    {
        id: '3',
        title: 'Reclaim Your Assets',
        description: 'With the appropriate solution, let us retrieve your funds promptly!',
        icon: <FaBitcoin size={70} className='lightTheme' />,
        className: 'lightTheme'
    },

]

export const testimonials = [
    {
        id: 13,
        name: "Emily Roberts",
        description: "I'm incredibly grateful to Crypto Recovery for helping me recover my crypto assets. Their expertise and dedication are unmatched.",
        rating: '5',
        avatar: avatar1,
    },

    {
        id: 14,
        name: "Daniel Wilson",
        description: "Crypto Recovery exceeded my expectations in every way. They were able to recover my lost funds quickly and with utmost professionalism.",
        rating: '5',
        avatar: avatar2,
    },

    {
        id: 15,
        name: "Sophie Johnson",
        description: "I highly recommend Crypto Recovery to anyone in need of crypto asset recovery. Their team is knowledgeable and reliable.",
        rating: '5',
        avatar: avatar3,
    },

    {
        id: 16,
        name: "David Thompson",
        description: "Thanks to Crypto Recovery, I was able to regain access to my locked crypto wallet. Their expertise and support made the process smooth.",
        rating: '5',
        avatar: avatar4,
    },

    {
        id: 17,
        name: "Lily Davis",
        description: "Crypto Recovery is a trusted partner for crypto asset recovery. They have the skills and resources to solve complex cases.",
        rating: '5',
        avatar: avatar5,
    },

    {
        id: 18,
        name: "Matthew Brown",
        description: "I was amazed by Crypto Recovery's efficiency in recovering my lost crypto funds. They provided excellent service throughout the process.",
        rating: '5',
        avatar: avatar6,
    },

    {
        id: 19,
        name: "Oliver Martinez",
        description: "Crypto Recovery saved me from a frustrating situation by successfully recovering my inaccessible crypto assets. I'm forever grateful.",
        rating: '5',
        avatar: avatar8,
    },

    {
        id: 20,
        name: "Grace Wilson",
        description: "I had lost hope, but Crypto Recovery restored my faith in crypto asset recovery. They are reliable and trustworthy.",
        rating: '5',
        avatar: avatar7,
    },

    {
        id: 21,
        name: "Benjamin Adams",
        description: "Crypto Recovery's professionalism and expertise are unmatched. They successfully recovered my lost crypto assets.",
        rating: '5',
        avatar: avatar10,
    },

    {
        id: 22,
        name: "Sophia Martin",
        description: "I highly recommend Crypto Recovery for their exceptional service and commitment to recovering lost crypto assets.",
        rating: '5',
        avatar: avatar9,
    },

    {
        id: 23,
        name: "Jacob Thompson",
        description: "Crypto Recovery provided me with the peace of mind I needed by successfully recovering my lost crypto funds.",
        rating: '5',
        avatar: avatar12,
    },

    {
        id: 24,
        name: "Ella Johnson",
        description: "I'm impressed by Crypto Recovery's professionalism and dedication to helping individuals recover their crypto assets.",
        rating: '5',
        avatar: avatar11,
    }
]

export const services = [
    {
        name: 'Financial Deception',
        icon: <FaIdCard size={80} color="#fff" />,
        description: 'Falling victim to fraudulent schemes that result in the loss of your digital assets.',
    },
    {
        name: 'Transaction Challenges',
        icon: <TfiReload size={80} color="#fff" />,
        description: 'Facing difficulties in transferring your cryptocurrencies due to technical issues or errors.',
    },
    {
        name: 'Coin Theft',
        icon: <FaMask size={80} color="#fff" />,
        description: 'Experiencing the unauthorized appropriation of your coins by malicious individuals.',
    },
    {
        name: 'Online Harassment',
        icon: <HiMailOpen size={80} color="#fff" />,
        description: 'Being subjected to online harassment or blackmail related to your crypto holdings.',
    },
    {
        name: 'Password Loss',
        icon: <FaUserLock size={80} color="#fff" />,
        description: 'Forgetting or misplacing the passwords or keys required to access your cryptocurrency.',
    },
    {
        name: 'Forked Coin Recovery',
        icon: <BsCoin size={80} color="#fff" />,
        description: 'Assisting you in recovering coins resulting from blockchain forks or airdrops.',
    },
    {
        name: 'Exchange Breach',
        icon: <FaExchangeAlt size={80} color="#fff" />,
        description: 'Recovering funds affected by security breaches or hacking incidents on cryptocurrency exchanges.',
    },
    {
        name: 'Ransomware Incidents',
        icon: <AiOutlineWarning size={80} color="#fff" />,
        description: 'Dealing with ransomware attacks where your crypto assets are held hostage.',
    },
    {
        name: 'Hard Drive Damage',
        icon: <FiHardDrive size={80} color="#fff" />,
        description: 'Recovering cryptocurrencies from damaged or inaccessible hard drives or storage devices.',
    },
    {
        name: 'Virus Impact',
        icon: <FaShieldVirus size={80} color="#fff" />,
        description: 'Addressing the impact of viruses and ransomware on your cryptocurrency holdings.',
    },
    {
        name: 'Technical Issues',
        icon: <MdOutlineSyncProblem size={80} color="#fff" />,
        description: ' Resolving issues arising from software glitches or compatibility problems affecting your crypto investments.',
    },
    {
        name: 'Data Breach Recovery',
        icon: <BsFillClipboardDataFill size={80} color="#fff" />,
        description: 'Helping you recover funds compromised in data breaches or security compromises.',
    },
]

export const highlights = [
    {
        icon: <AiOutlineCloudDownload size={70} color="#0068FF" />,
        title: 'CRYPTO FUND RECOVERY',
        description: 'Recover stolen or lost cryptocurrency funds with our expertise and proven methods. We have helped numerous scam victims worldwide reclaim millions of dollars. Our investigations track crypto movements, identify perpetrators, and assist in seizing and recovering your funds.'
    },
    {
        icon: <GiBreakingChain size={70} color="#0068FF" />,
        title: 'BLOCKCHAIN INVESTIGATIONS',
        description: 'Utilizing advanced blockchain forensics tools and techniques, we provide investigation services, cooperate with exchanges and law enforcement, and offer expert witness testimony. Our goal is to successfully trace and recover your funds through comprehensive blockchain analytics.'
    },
    {
        icon: <MdOutlineVisibilityOff size={70} color="#0068FF" />,
        title: 'UNCOVER HIDDEN CRYPTOCURRENCY',
        description: 'Unveil concealed cryptocurrency assets used in cases of divorce, embezzlement, bankruptcy, financial fraud, and tax evasion. Our tracking and auditing capabilities trace crypto movements, identify ownership, and provide forensic reports, declarations, and expert witnesses.'
    },
]

export const moreData = [
    {
        eventKey: '0',
        header: "FRAUD -SCAMMED BY CRYPTO TRADING?",
        description: ' Crypto scams are the top threat to investors by far, say securities according to a survey of securities regulators NASAA (North American Securities Administrators Association) Scamming was the greatest form of cryptocurrency-based crime in 2021, followed by theft — most of which occurred through hacking of cryptocurrency businesses. Scammers took home a record $14 billion in cryptocurrency in 2021,Stories of ‘crypto millionaires’ attracted some investors to try their hand at investing in cryptocurrencies or crypto-related investments. The most common telltale sign of an investment scam is an offer of guaranteed high returns with no risk If you have lost bitcoin or cryptocurrency to a fraudulent scheme, you can recover the lost cryptocurrency using Crypto Recovery. We are One of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds.'
    },
    {
        eventKey: '1',
        header: "“CRYPTO RECOVERY SERVICE” FRAUDS",
        description: 'If you recently lost a portion of your savings or retirement nest egg to a cryptocurrency, an offer to recover your lost funds may sound very appealing. Unfortunately, for many victims of fraud, the offer may be another scheme that adds insult to injury. Recovery scams are a form of advance-fee fraud—when you are asked to pay upfront for the chance of getting a much bigger sum of money later. Recovery frauds target victims already harmed by other frauds. Sometimes, victims are told that most or all their money will assuredly be returned if they first pay a small donation, retainer, or overdue taxes. However, after making the first payment, requests for more funds often follow. If you’ve been a recent victim of fraud, be prepared to guard against these follow-on schemes. Speak to one of our specialists at Crypto Recovery, We are One Of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds.'
    },
    {
        eventKey: '2',
        header: "FORGET OR MISPLACE YOUR PASSWORD",
        description: ' Tens of billions worth of Bitcoin have been locked by people who forgot their key. Analysis by the Wall Street Journal suggests 20% of all existing Bitcoin tokens — currently worth around $140 billion, have been misplaced by users and appears to be in lost or otherwise stranded wallets. Because Bitcoin investors hold their tokens in digital wallets that’s protected by cryptography and accessible only via private key, if the wallet owner loses his or her access key, that wallet may be permanently inaccessible, along with the tokens it contains. The cryptocurrency’s unusual nature has meant that many people are locked out of their Bitcoin fortunes as a result of lost or forgotten keys. They have been forced to watch, helpless, as the price has risen and fallen sharply, unable to cash in on their digital wealth. Bitcoin owners who are locked out of their wallets speak of endless days and nights of frustration as they have tried to get access to their fortunes. Many have owned the coins since Bitcoin’s early days a decade ago, when no one had confidence that the tokens would be worth anything.If you forget or misplace your password, the funds are completely inaccessible. Crypto Recovery engineers can help. We have established techniques for recovering or breaking cryptocurrency wallet passwords, and in many cases, we can restore access within several days.'
    },
    {
        eventKey: '3',
        header: "DELETED YOUR WALLET",
        description: 'Accidental deletion is one of the most common data loss scenarios. In the vast majority of cases, we can successfully recover deleted wallets without any data loss, and we offer no data, no charge guarantee.Important note: If you have accidentally deleted a wallet, turn off the affected device immediately to avoid permanent data loss. If you accidentally deleted data, and you fear your cryptocurrencies are lost, Crypto Recovery can help to recover deleted data.'
    },
    {
        eventKey: '4',
        header: "HARDWARE, SOFTWARE ERRORS AND VIRUSES:",
        description: 'Corrupt Data occurs due to software or hardware errors, and defects, even coins stored on mobile phones, computers, USB drives, or hard discs it can prevent cryptocurrency owners from accessing their coins — even with a correct password. Crypto Recovery can definitely help restore the affected files and sometimes within hours. If the computer or device with your cryptocurrencies is infected with Viruses, let Crypto Recovery help you to try to clear the virus without putting your cryptocurrency in jeopardy. To date we have recovered large amount of cryptocurrency from failed hard drives, RAID arrays, flash drives, SSD’s and other devices. As one of the most trusted crypto recovery services, we offer the highest success rates available anywhere, and we use state-of-the-art technology. If your data is still on your device, we have the necessary tools to recover it.'
    },
    {
        eventKey: '5',
        header: "TROUBLE UPGRADING OLDER WALLET VERSIONS",
        description: 'If you are not able to open your wallet with the current version of wallet software or uncomfortable upgrading older wallets. But you know your password, Crypto Recover can help you to resolve the software problem. We can also provide safe, secure upgrades, providing you with an up-to-date wallet while maintaining your privacy.'
    },
    {
        eventKey: '6',
        header: "TRANSFERRED TO AN INVALID ADDRESS",
        description: 'Was your cryptocurrency accidentally sent to the wrong address? Or maybe the funds were never received by the recipient, Crypto Recovery is guaranteed to help you recover them.'
    },
    {
        eventKey: '7',
        header: "YOUR FUNDS LOST IN FORKS",
        description: 'Forks are splits that happen in the transaction chain based on different user opinions about transaction history. If you have a bitcoin balance that predates the fork, Crypto Recovery work with established and leading vendors, experts and professional blockchain forensics tools, and use a combination of proven blockchain analytics and investigative techniques to successfully return your funds.'
    },


]

export const serviceHighlight = [
    {
        eventKey: '0',
        header: "Supported Currencies and Wallets.",
        description: 'We can assist in the recovery of any type of wallet and cryptocurrency. We have expertise in recovering funds from various wallets and cryptocurrencies.Our comprehensive recovery services cover a wide range of wallets and cryptocurrencies. Whether you have lost Bitcoin, Ethereum, or any other digital asset, our skilled team possesses the expertise and resources required to assist you.'
    },
    {
        eventKey: '1',
        header: "Success Rate and Possibility.",
        description: 'While we cannot provide guarantees, we carefully evaluate each case potential for recovery and offer honest assessments. Our priority is to work in the best interest of our clients, assessing the likelihood of successful recovery. While we cannot offer guarantees due to the unique nature of each case, we conduct a thorough evaluation of your situation. Our experienced team provides an honest assessment of the likelihood of successful recovery and guides you on whether it is advisable to proceed with our services.'
    },
    {
        eventKey: '2',
        header: "Cost and Pricing",
        description: 'Pricing is determined based on factors such as the value of your holdings, security complexities, legal considerations, and other relevant factors. We provide flexible pricing options, including contingency-based fees and competitive rates. Our pricing structure is tailored to the specific circumstances of your case. Factors such as the value of your lost holdings, the complexity of security issues, legal considerations, and other relevant complexities are taken into account. We offer personalized pricing options designed to provide transparency and flexibility. Our fees typically start at a competitive rate of 15% of the recovered amount.'
    },
    {
        eventKey: '3',
        header: "Recovery Timeframe",
        description: 'We strive to complete the recovery process efficiently, aiming to deliver results within a few weeks. Our goal is to expedite the recovery process while maintaining effectiveness. We understand the importance of a timely recovery process. While the timeframe can vary depending on the specific details of your case, our dedicated team is committed to delivering results as quickly as possible. Leveraging advanced techniques and resources, we aim to achieve tangible outcomes within the first few weeks, minimizing the waiting time for you.'
    },
    {
        eventKey: '4',
        header: "Cryptocurrency Return Process",
        description: 'Once the recovery is successful, we ensure the secure return of your cryptocurrency. Our team, in collaboration with trusted law firms, facilitates the complete return of your valuable investment. Upon successful recovery, we prioritize the secure return of your cryptocurrency. Our collaborative efforts with trusted law firms ensure a seamless process for the complete restoration of your valuable investment. Following the completion of the necessary investigations by our computer and investigation experts, the selected law firm facilitates the return of your funds, granting you full access to your assets and reinstating your control and ownership.'
    },

]