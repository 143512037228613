import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'
import { testimonials } from '../../../data'
import Rating from '../rating'
import { shuffleArray } from '../../../hooks/shuffleArray';




const Testimonials = ({ title, subTitle }) => {
    return (
        <div className='testimonialWrapper'>
            <Container>
                <div className='testimonialTitleContainer' data-aos="fade-up" data-aos-duration="500">
                    <p>{title}</p>
                </div>
                <div className='testimonialSubTitleContainer' data-aos="fade-up" data-aos-duration="1000">
                    <p>{subTitle}</p>
                </div>
                <div className='testimonialsContainer' data-aos="fade-up" data-aos-duration="1500">
                    {
                        shuffleArray(testimonials).map(testimonial => (
                            <div className='testimonialContainer' key={testimonial.id}>

                                <div className='testimonialHeaderContainer'>
                                    <div className='testimonialAvatar'>
                                        <img src={testimonial.avatar} width={70} height={70} alt='testimonialAvatar' />
                                    </div>
                                    <div className='ratingNameContainer'>
                                        <div className="nameContainer">
                                            <p>{testimonial.name}</p>
                                        </div>
                                        <div className='ratingContainer'>
                                            <Rating rating={testimonial.rating} />
                                        </div>

                                    </div>

                                    <div className='testimonialBodyContainer'>
                                        <p>{testimonial.description}</p>
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </Container>
        </div>
    )
}

export default Testimonials